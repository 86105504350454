.image__popup--container {
  @apply fixed inset-0 z-20;
}
.image__popup--row {
  @apply w-full h-full relative bg-[#0000003b] flex items-center justify-center;
}
.image__popup--col {
  @apply absolute z-10 bg-white w-[80%] h-[80%];
}
.image__popup--wrapper {
  @apply relative w-full h-full scroll-smooth overflow-y-scroll;
}
.image__popup--close-btn {
  @apply absolute z-10 text-red-500 rounded-full bg-white shadow-lg p-2 -top-3 -right-3 cursor-pointer;
}
.image__popup--close-btn-two {
  @apply absolute z-10 text-red-500 rounded-full bg-white shadow-lg p-1 -top-3 right-0 cursor-pointer;
}
