@tailwind base;
@tailwind components;
@tailwind utilities;

.ck-content {
	min-height: 500px;
	max-height: auto;
}

.tox-statusbar__branding {
	@apply !hidden;
}

.chock-icon * {
	@apply stroke-black/60 transition-colors;
}
.chock-icon:hover * {
	@apply stroke-black;
}

.page-loader {
	@apply fixed top-0 left-0 w-full h-full py-4 bg-white/70;
	@apply z-[999] flex justify-center items-center;
	backdrop-filter: saturate(180%) blur(5px);
}

.page-loader .bg {
	@apply w-full opacity-10;
}

/* html contents default styles */
.ck-editor__editable {
	min-height: 250px;
	max-height: 500px;
	overflow-y: auto;
}
.full-screen-ck-editor .ck-editor__editable {
	min-height: 80vh;
	max-height: 80vh;
	overflow-y: auto;
}

.ck-editor__main > * {
	/* @apply mb-5; */
	@apply max-w-[100%] overflow-x-auto;
}
.ck-editor__main {
	line-height: 1.8;
}
.ck-editor__main * {
	line-height: 1.8;
}
.ck-editor__main h1 {
	@apply !text-2xl md:!text-3xl font-bold;
	line-height: 1.8;
}
.ck-editor__main h2 {
	@apply !text-xl md:!text-2xl font-bold;
	line-height: 1.8;
}
.ck-editor__main h3 {
	@apply !text-lg md:!text-xl font-bold;
	line-height: 1.8;
}
.ck-editor__main h4 {
	@apply text-lg font-bold;
	line-height: 1.8;
}
.ck-editor__main h5 {
	@apply text-base font-bold;
	line-height: 1.8;
}
.ck-editor__main h6 {
	@apply text-base font-bold;
}
.ck-editor__main p {
	@apply text-base leading-[1.5rem];
}
.ck-editor__main a {
	@apply underline;
}
.ck-editor__main a:hover {
	/* @apply text-primary-dark; */
}
.ck-editor__main img {
	@apply mx-auto;
}
.ck-editor__main ul {
	@apply list-disc pl-5 leading-5;
}
.ck-editor__main ol {
	@apply list-decimal pl-5 leading-5;
}
.ck-editor__main li {
	@apply pt-1;
}
.ck-editor__main img {
	@apply !max-w-[100%] !h-auto;
}
.ck-editor__main table {
	@apply max-w-full;
	border-width: 1px;
}
.ck-editor__main table td {
	@apply pr-1 md:pr-4 pb-2 md:pb-2 pl-1 !text-left;
	border-width: 1px;
}
.ck-editor__main table td span {
	/* @apply !text-xs md:!text-base; */
}

.react-datepicker-popper {
	z-index: 10 !important;
}
